import styled from 'styled-components';

const List = styled.a`
    list-style-type: square;
    flex: display;
    flex: 1;
    font-size: 1.2em;
    background-color: ;
    font: 'Roboto Condensed';
    text-align: ${props => props.primary ? "right" : "justify"};
    color: #003300;
    padding-left: 18%;
    padding-right: 15%;
    line-height: 1.6em;
    text-indent: ;
    padding-right: ${props => props.primary ? 3 : 15};
`;

export default List;