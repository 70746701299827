import styled from 'styled-components';


const Title = styled.h1`
    font-size: 2.8em;
    text-align: left;
    padding-left: 4em;
    font-family: 'Amatic SC', cursive;
    color: black;
    padding-left: 15%;
    background-color: #77b300 ;
`;

export default Title