import styled from 'styled-components';

const Paragraph = styled.p`
  flex: display;
  font-size: 1.2em;
  background-color: ;
  font: 'Roboto Condensed';
  text-align: ${props => props.primary ? "right" : "justify"};
  color: #003300;
  padding-left: 15%;
  padding-right: 15%;
  line-height: 1.6em;
  background-color:  ;
`;



export default Paragraph;