import styled from 'styled-components';

const Logostyle = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 100%;
    height: auto;
    margin-top: 5%;
`;

export default Logostyle;