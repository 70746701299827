import React from 'react';
import './navigation.css';

function Navigation() {
    return (
        <nav className="navigation">
            <ul>
                <li><a href="#O mně">O mně</a></li>
                <li><a href="#Služby Ceník">Služby</a></li>
                <li><a href="#Portfolio">Portfolio</a></li>
                <li><a href="#Reference">Reference</a></li>
                <li><a href="#Kontakt">Kontakt</a></li>
            </ul>
        </nav>
    );
}

export default Navigation;
