import styled from 'styled-components';

const Restrictor = styled.div`
  flex: center;
  flex: 1;
  font-size: 1.2em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 33%;
`;

export default Restrictor;