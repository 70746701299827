import styled from 'styled-components';

const SubTitle = styled.h1`
    font-size: 2.1em;
    text-align: left;
    font-family: Amatic SC;
    color: #4d4d33;
    padding-left: 15%;
    padding-bottom: 0.1px;
`;

export default SubTitle